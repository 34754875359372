<template>
  <main class="recovery-page page">
    <div class="recovery-page__inner container-padding">
      <h2 class="recovery-page__title">Ведите новый пароль</h2>
      <h4 class="recovery-page__title">Придумайте пароль для дальнейшего использования</h4>
      <form @submit.prevent="submit" class="recovery-page__form">
        <InputComponent v-model="form.email.value" :errors="form.email.errors" placeholder="E-mail" />
        <InputComponent
          v-model="form.password.value"
          :errors="form.password.errors"
          type="password"
          placeholder="Новый пароль"
        />
        <InputComponent
          v-model="form.confirmed_password.value"
          :errors="form.confirmed_password.errors"
          type="password"
          placeholder="Подтвердите пароль"
        />
        <button class="btn btn--md btn--main">
          <IconComponent v-if="loading" name="loading" />
          <span v-else>Восcтановить пароль</span>
        </button>
      </form>
    </div>
  </main>
</template>

<script>
import InputComponent from "components/inputs/InputComponent.vue";
import RESET from "@/graphql/auth/UserResetPassword.graphql";
import IconComponent from "components/IconComponent.vue";
export default {
  name: "RecoveryPage",
  components: { IconComponent, InputComponent },
  data() {
    return {
      token: null,
      loading: false,
      form: {
        password: {
          value: null,
          errors: [],
          show: false,
        },
        confirmed_password: {
          value: null,
          errors: [],
          show: false,
        },
        email: {
          value: null,
          errors: [],
        },
      },
    };
  },
  mounted() {
    let params = this.parseParams(new URLSearchParams(window.location.search));
    this.token = params.password_reset_token;
  },
  methods: {
    submit() {
      this.loading = true;
      this.resetErrors();
      this.$apollo
        .mutate({
          mutation: RESET,
          variables: {
            token: this.token,
            email: this.form.email.value,
            password: this.form.password.value,
            password_confirmation: this.form.confirmed_password.value,
          },
        })
        .then(() => {
          this.loading = false;
          this.$router.push({ name: "home" });
          this.$notify({
            title: "Готово",
            duration: 5000,
            speed: 200,
            type: "success",
          });
        })
        .catch(({ graphQLErrors }) => {
          this.parseGqlErrors(graphQLErrors);
          this.loading = false;
          this.$notify({
            title: "Ошибка",
            duration: 5000,
            speed: 200,
            type: "error",
          });
        });
    },
    resetErrors() {
      Object.keys(this.form).forEach((key) => {
        this.form[key].errors = [];
      });
    },
    parseGqlErrors(graphQLErrors) {
      graphQLErrors.forEach((err) => {
        if (err.extensions.category === "validation") {
          Object.keys(err.extensions.validation).forEach((key) => {
            if (this.form[key]) {
              this.form[key].errors.push(err.extensions.validation[key][0]);
            }
          });
        }
      });
    },
    parseParams(params) {
      let parsedParams = {};
      params.forEach((value, key) => {
        try {
          parsedParams[key] = JSON.parse(value);
        } catch (e) {
          parsedParams[key] = value;
        }
      });
      return parsedParams;
    },
  },
};
</script>

<style lang="stylus">
.recovery-page {
  width 100%
  //padding-top: 80px

  &__title {
    margin 0
  }

  &__inner {
    display flex
    flex-direction column
    gap 20px
    align-items center
    max-width 600px
  }

  &__form {
    width 100%
    display flex
    flex-direction column
    gap 17px
  }
}
</style>
